import React from 'react';

import { Layout, SEO } from 'components';
import { Skills } from 'containers';

const SkillsPage = () => (
  <Layout>
    <SEO title='Skills' />
    <Skills />
  </Layout>
);

export default SkillsPage;
